import React from "react"
import { Button, Layout } from 'antd'
import SiteLayout from "../components/SiteLayout";


//@ts-ignore 
import styles from './personvern.module.scss';
import YourData from "../components/Sections/YourData";


export default ({ children }) => (
    <SiteLayout>
        <YourData />
<div className={styles.smallcontainer}>
<h1>Personvern</h1>
<p>Når du bruker Oss-Appen (“Oss” eller “Appen”) gir du oss tilgang til personopplysninger om deg. Oss Norge AS
    (“Selskapet”, “vi” “våre” eller “oss”), organisasjonsnummer 919 439 734, er behandlingsansvarlig for behandlingen
    av personopplysningene. Det er inngått en egen avtale mellom brukere av Appen og oss ("Avtalen").
</p>
<p>
    En personopplysning er en opplysning eller vurdering som kan knyttes til deg som enkeltperson. I denne
    personvernerklæringen beskriver vi hvilke opplysninger vi får tilgang til, hvordan vi får tilgang til
    opplysningene, og hva vi bruker opplysningene til. Hvis du har noen spørsmål angående vår behandling av dine
    personopplysninger, vennligst chat med oss gjennom Oss-Appen eller vår hjemmeside eller send en epost til
    hei@oss.no.
</p>
<ol>
    <li>Informasjon vi samler inn om deg gjennom Oss-Appen og Oss-Brikken
        <ol>
            <li>Vi behandler informasjonen som du selv oppgir i Appen: navn, adresse, fødselsdato, telefonnummer,
                e-postadresse, målernummer og målerpunkt-id, nettleverandør og målertype. I tillegg behandler vi
                opplysninger knyttet til strømforbruket ditt. Formålet er å levere avtalte tjenester til deg basert på
                inngått avtale og eventuelle samtykker. I tillegg ivaretar vi forsknings- og statistikkformål gjennom
                vårt samarbeid med Enova SF, som beskrevet i Avtalen punkt 3.7. Noen opplysninger må du oppgi for å
                kunne bruke Appen. For formål som er basert på samtykke, kan samtykket trekkes tilbake til enhver tid.
            </li>
            <li>
                Kontaktinformasjon benyttes slik at vi kan kontakte deg ved behov.
            </li>
            <li>Dersom du oppretter et fellesskap med andre i Appen for å dele informasjon fra den, vil
                kontaktinformasjon benyttes slik at dere skal kunne dele data i deres fellesskap. Når du skal invitere
                venner og kjente til fellesskapet ber vi om tilgang til dine kontakter. Vi lagrer på intet tidspunkt
                din kontaktliste og vi sender den heller ikke til andre.
            </li>
            <li>
                Dersom du har gitt oss eksplisitt samtykke til det i Oss-Appen, vil vi samle informasjon knyttet
                til din bruk av Appen for å forbedre tjenesten. Slik informasjon kan være enhetstype, feilmeldinger og
                bruksmønster.
            </li>
            <li>
                Vi ber om tilgang til funksjoner på din telefon som kan generere innsamling av ytterligere
                personopplysninger. Dette for å gi deg den beste brukeropplevelsen. For eksempel kan vi be om tilgang
                til:
                <ol>
                    <li>
                        Kamera og bildealbum – Når du oppretter en profil i Oss kan du velge å skanne QR-koden på
                        strømmåleren. Du kan også laste opp et profilbilde. Til dette trenger vi tilgang til ditt
                        kamera og
                        bildealbum. Vi har ikke tilgang til andre bilder enn de du velger å bruke i Appen.
                    </li>
                    <li>Push-varslinger – For å kunne gi deg informasjon om hendelser knyttet til din husstand eller
                        deltakelse i et fellesskap for å dele strømdata, trenger vi tillatelse til å sende deg
                        push-varsler. Du
                        kan når som helst skru dette av i innstillingene på din enhet.
                    </li>
                </ol>

            </li>
        </ol>
    </li>

    <li>
        Oppbevaring av personopplysninger
        <ol>
            <li>Vi lagrer personopplysningene så lenge du er bruker av Oss. Du avslutter ditt brukerforhold ved å
                si opp Avtalen med oss. Etter oppsigelse av Avtalen sletter eller anonymiserer vi personopplysningene i
                løpet av 30 dager. Dersom du ikke bruker Appen i løpet av 1 år, anser vi Avtalen som oppsagt og dine
                personopplysninger vil bli slettet eller anonymisert. Vi må oppbevare personopplysningene i lengre tid
                dersom det er pålagt i lovgivningen, eller dersom det oppstår et særlig behov, for eksempel ved
                rettslige krav som rettes mot oss eller av oss.</li>
            <li>
                Når vi lagrer dine data gjør vi det så langt som mulig i så avidentifisert form som mulig av
                hensyn til informasjonssikkerheten og slik at dersom opplysningene kommer på avveie, så har det minst
                mulig konsekvenser for deg.
                Vi lagrer aldri ditt målernummer direkte knyttet til data fra din strømmåler. I stedet benytter vi en
                "hash" som er bygget opp av flere unike deler og informasjon utenfor kode og database. Vi bruker SHA512
                på disse delene slik at man får en unik hash for målernummeret som ikke kan oversettes tilbake.

                Hashing av målernummer er det første som skjer med dine data etter vi har tolket pakkene som kommer fra
                Oss-Brikken. Fra Oss-Brikken og til oss sendes data kryptert over 4G.

                Ingen som behandler data ser det faktiske målernummeret. Dette gjelder alle systemene vi utvikler og
                benytter.

            </li>
            <li>Vi benytter Microsoft Azure som underleverandør og deres datasenter i EØS-området slik at ingen
                personopplysninger overføres til tredjeland. </li>

        </ol>
    </li>

    <li>Dine rettigheter
        <ol>
            <li>
                Du har krav på å motta informasjonen vi har om deg (såkalt "dataportabilitet"), og en slik
                mulighet er innarbeidet i Oss-Appen. Videre har du rett til innsyn i dine personopplysninger. Dersom
                dine personopplysninger er feil, har du rett til å få dem rettet. Personopplysninger vi eventuelt ikke
                har grunnlag for å behandle, skal slettes, og du kan kreve at dette gjøres dersom vi ikke har sørget
                for det på eget initiativ. Du kan be om at vi begrenser bruken av dine opplysninger. Du kan motsette
                deg vår bruk av dine opplysninger. Dersom du mener at vi behandler dine personopplysninger uten
                rettslig grunnlag, kan du også klage til Datatilsynet, men vi anmoder deg om å kontakte oss slik at vi
                først kan ta stilling til dine innvendinger og slik at vi kan oppklare eventuelle misforståelser.
            </li>
        </ol>
    </li>


    <li>Informasjon vi samler inn om deg gjennom Oss.no
        <ol>
            <li>
                Informasjonskapsler (cookies)<br />
                <p>
                    Hjemmesidens eier og kontaktopplysninger
                    Denne hjemmeside er drevet og eiet av
                </p>
                <p>
                    OSS NORGE AS<br />
                    Grønland 67<br />
                    3045 DRAMMEN<br />
                    919 439 734<br />
                    hei@oss.no
                </p>

            </li>
            <li>Hva er en cookie?
                <p>
                    En cookie er en liten tekst-fil som vi lagrer på din PC. Dette gjør vi får å kunne holde orden på
                    hva der skjer under ditt besøk og for vi skal kunne gjenkjenne din PC. En cookie inneholder kun
                    tekst, er ikke et program og inneholder ikke virus.
                </p>

            </li>
            <li>Hjemmesidens bruk av cookies
                <p>
                    Cookies er nødvendige for å få hjemmesiden til å fungere. Cookies hjelper oss dessuten med å få
                    overblikk over ditt besøk på hjemmesiden, slik at vi løpende kan optimere og målrette hjemmesiden
                    til dine behov og interesser. Cookies husker f.eks., hva du har lagt i en eventuell innkjøpskurv,
                    om du tidligere har besøkt siden, om du er logget inn, samt hvilket språk og valuta du gjerne vil
                    ha vist på hjemmesiden. Vi bruker også cookies til å målrette våre annonser til deg på andre
                    hjemmesider. Overordnet benyttes cookies som som del av vår løsning for å vise innhold som er så
                    relevant som mulig for deg. Her er oversikten over hjemmesidens bruk av cookies, og våre
                    samarbeidspartnere i denne forbindelse:
                </p>
                <p>
                    <script id="CookiePolicy" src="https://policy.cookieinformation.com/f6a9d370-1bee-4869-a6b6-191a142c13d4/cd.js"
                        type="text/javascript" data-culture="NB" async></script>
                </p>

            </li>
            <li>Slik avviser eller sletter du dine cookies
                <p>Avvisning av cookies:</p>


                <p>Du kan alltid avvise cookies på din PC ved å endre innstillingene i d in Internett-browser.
                    Hvor du finner disse innstillingene avhenger av hvilken browser du benytter. Du skal imidlertid
                    være oppmerksom på at dersom du gjør dette, vil det være mange funksjoner og tjenester du ikke kan
                    benytte fordi disse funksjoner og tjenester forutsetter at hjemmesiden kan huske de valg du
                    foretar.</p>


                <p>Sletting av cookies:
                    Cookies som du tidligere har akseptert, kan senere enkelt slettes. Benytter du en PC med en nyere
                    Internett-browser, kan du slette dine cookies ved å benytte snarveistastene: CTRL + SHIFT + Delete.
                    Dersom disse ikke fungerer, og/eller benytter du en MAC, må du først avklare hvilken browser du
                    benytter og deretter klikke på den relevante linken under:
                    <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=no" target="_new">Google Chrome</a>
                    <a href="https://support.apple.com/no-no/HT201265" target="_new">Safari</a>
                    <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" target="_new">Firefox</a>
                </p>

                Husk: Benytter du flere Internett-browsere, må du slette cookies i hver enkelt.

                <p>Har du spørsmål?</p>
                <p>Cookiedeklarasjonen oppdateres hver måned av Cookie Information.
                    Hvis du har noen spørsmål, så skriv til info@cookieinformation.com.
                </p>
            </li>
            <li>Markedsføring
                <p>Oss Norge AS kan bruke data om brukere på oss.no dersom du samtykker til det for å tilpasse
                    digitale annonser og tilbud. Vi bruker informasjonskapsler utstedt av tredjepart (f.eks. Google),
                    som gjør at du kan oppleve å få markedsføringsbudskap fra oss i etterkant av ditt besøk.
                    Informasjonskapslene inneholder kun aggregert informasjon og ingen identifiserbare opplysninger om
                    deg og din atferd.
                </p>

                Tredjeparter får kun tilgang til opplysninger på aggregert nivå.
            </li>

        </ol>

    </li>
</ol>
</div>
    </SiteLayout>
)
